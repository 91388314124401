<route>
{
  "meta": {
    "auth": "reservationEdit,reservationAdd"
  }
}
</route>

<template>
  <div class="page">
    <div class="back" @click="$router.go(-1)">返回</div>
    <el-card class="tableContainer">
      <el-form label-width="8em">
        <el-form-item required label="规则名称">
          <el-input style="width:200px" v-model.trim="form.name"></el-input>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <span>每周规则</span>
            <el-tooltip class="item" effect="dark" content="可预约单位是核销码数量" placement="right">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <div class="weeklyTable">
            <div class="tableRow tableHeader">
              <div class="name"></div>
              <div class="content" v-for="(i, n) in ['周一', '周二', '周三', '周四', '周五', '周六', '周日']" :key="n">
                {{ i }}
              </div>
              <div class="action">操作</div>
            </div>
            <div class="tableRow" v-for="(row, rowIndex) in form.weeklyData" :key="rowIndex">
              <div class="name">{{ row.clockStart }} ~ {{ row.clockEnd }}</div>
              <div class="content" v-for="i in 7" :key="i">
                <el-input
                  style="width:60px"
                  size="mini"
                  oninput="value=value.replace(/[^0-9]/g,'')"
                  v-model="row.content[i - 1]"
                ></el-input>
              </div>
              <div class="action">
                <el-button
                  plain
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="handleDeleteWeeklyRow(rowIndex)"
                ></el-button>
              </div>
            </div>
            <div class="tableRow end">
              <div class="content">
                <el-button type="success" icon="el-icon-plus" @click="handleAddWeeklyRow">添加</el-button>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item required label="提前预约设置">
          <el-switch v-model="form.isNeedAhead"></el-switch>
          <el-form v-if="form.isNeedAhead" label-width="11em">
            <el-form-item required label="需要提前">
              <el-input style="width:200px" oninput="value=value.replace(/[^0-9.]/g,'')" v-model="form.aheadMin">
                <template slot="append">时</template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <div class="tips">最低单位为半小时</div>
            </el-form-item>
            <el-form-item required label="最高可提前预约天数">
              <el-input style="width:200px" v-model="form.aheadMax" oninput="value=value.replace(/[^0-9.]/g,'')">
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <div class="tips">输入0则不限制，建议设置不超过60天</div>
            </el-form-item>
          </el-form>
        </el-form-item>
        <el-form-item label="特定日期">
          <el-form>
            <el-form-item>
              <el-button type="success" icon="el-icon-plus" @click="addDateData.isShow = true"
                >添加特定日期规则</el-button
              >
            </el-form-item>
            <el-form-item>
              <div class="specialData">
                <div class="item" v-for="(i, n) in form.specialData" :key="n">
                  <div class="itemHeader">
                    <span>{{ i.date }}</span>
                    <div>
                      <el-button type="primary" plain size="mini" @click="handleEditSpecialDate(i)">编辑时段</el-button>
                      <el-button type="danger" plain size="mini" @click="form.specialData.splice(n, 1)"
                        >删除日期</el-button
                      >
                    </div>
                  </div>
                  <div class="clockList">
                    <template v-if="i.clockList.length">
                      <div class="clockItem" v-for="(clock, clockIndex) in i.clockList" :key="clockIndex">
                        <span> {{ clock.start }} - {{ clock.end }} </span>

                        <span>
                          <span style="font-size: 12px;color: #999;    padding-right: 10px;"> 可以预约人数</span>
                          <el-input
                            style="width:60px"
                            oninput="value=value.replace(/[^0-9]/g,'')"
                            size="mini"
                            v-model="clock.count"
                          ></el-input>
                        </span>
                      </div>
                    </template>
                    <div class="clockItem" v-else>暂无时段配置</div>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width:200px" @click="handleSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-dialog :close-on-click-modal="false" title="选择时段" :visible.sync="isShowClockPicker">
      <el-form label-width="4em">
        <el-form-item label="时段">
          <el-time-picker
            style="width:150px"
            format="HH:mm"
            value-format="HH:mm"
            v-model="addClockData1"
            placeholder="任意时间点"
          >
          </el-time-picker>

          至
          <el-time-picker
            style="width:150px"
            format="HH:mm"
            value-format="HH:mm"
            v-model="addClockData2"
            placeholder="任意时间点"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSubmitPickClock">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="选择日期" :visible.sync="addDateData.isShow" width="500px">
      <el-form label-width="4em">
        <el-form-item label="日期">
          <el-date-picker
            v-model="addDateData.date"
            type="dates"
            value-format="yyyy-MM-dd"
            :picker-options="addDateData.options"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSubmitPickDate">确定 </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :title="editSpecialDateData.date"
      :visible.sync="editSpecialDateData.isShow"
      width="500px"
    >
      <el-form label-width="4em">
        <el-form-item v-for="(i, n) in editSpecialDateData.clockList" :key="n">
          <el-time-picker
            is-range
            align="center"
            format="HH:mm"
            value-format="HH:mm"
            v-model="editSpecialDateData.clockList[n].clockRange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
          >
          </el-time-picker>
          <el-button
            type="danger"
            size="mini"
            circle
            plain
            icon="el-icon-delete"
            style="margin-left:5px"
            @click="editSpecialDateData.clockList.splice(n, 1)"
          ></el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="addSpecialDateClock">添加新时段 </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitEditSpecialDate">确定 </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    form: {
      id: '',
      name: '',
      isNeedAhead: false,
      aheadMin: 0.5,
      aheadMax: 30,
      weeklyData: [],
      specialData: []
    },
    addDateData: {
      isShow: false,
      date: '',
      options: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      }
    },
    editSpecialDateData: {
      isShow: false,
      date: '',
      clockList: []
    },
    isShowClockPicker: false,
    isLoading: false,
    addClockData1: '',
    addClockData2: ''
  }),
  computed: {
    ...mapGetters({})
  },
  watch: {},
  methods: {
    verifyForm() {
      return new Promise((resolve, reject) => {
        if (!this.form.name) {
          reject({ msg: '规则名称不能为空' })
        } else if (this.form.aheadMin <= 0) {
          reject({ msg: '提前预约时间不能为0' })
        } else if (this.form.aheadMin > 180) {
          reject({ msg: '最大可预约天数不能大约180' })
        } else if (!this.form.weeklyData.length) {
          reject({ msg: '每周规则不能为空' })
        } else {
          resolve()
        }
      })
    },
    submitAdd(payload) {
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      return this.verifyForm()
        .then(() => {
          return this.$http.post('/boom-center-product-service/sysAdmin/bookingRule', payload)
        })
        .then(res => {
          this.$message.success('新增成功')
          this.$router.push(`/vendor/vendorMange/reservation`)

          return
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    submitEdit(payload) {
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      return this.$http
        .put('/boom-center-product-service/sysAdmin/bookingRule', payload)
        .then(res => {
          this.$message.success('修改成功')
          this.$router.push(`/vendor/vendorMange/reservation`)
          return
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    handleSubmit() {
      let normalDateSettings = [
        { dayOfWeek: 1, timeIntervals: [] },
        { dayOfWeek: 2, timeIntervals: [] },
        { dayOfWeek: 3, timeIntervals: [] },
        { dayOfWeek: 4, timeIntervals: [] },
        { dayOfWeek: 5, timeIntervals: [] },
        { dayOfWeek: 6, timeIntervals: [] },
        { dayOfWeek: 7, timeIntervals: [] }
      ]
      normalDateSettings.forEach(i => {
        this.form.weeklyData.forEach(j => {
          i.timeIntervals.push({
            resources: Number(j.content[i.dayOfWeek - 1]),
            startTime: j.clockStart + ':00',
            endTime: j.clockEnd + ':00'
          })
          i.timeIntervals = i.timeIntervals.sort(
            (k, l) => Number(k.startTime.split(':')[0]) - Number(l.startTime.split(':')[0])
          )
        })
      })
      let specialDateSettings = this.form.specialData
        .map(i => {
          return {
            specialDay: i.date,
            timeIntervals: i.clockList
              .map(j => {
                return {
                  resources: Number(j.count),
                  startTime: j.start + ':00',
                  endTime: j.end + ':00'
                }
              })
              .sort((k, l) => Number(k.startTime.split(':')[0]) - Number(l.startTime.split(':')[0]))
          }
        })
        .sort((a, b) => new Date(a.specialDay).getTime() - new Date(b.specialDay).getTime())
      const payload = {
        ruleName: this.form.name,
        businessId: this.$store.getters['vendor/params/getVendorId'],
        bookAdvanceTime: Number(this.form.aheadMin) * 60,
        maxBookAdvanceDay: this.form.aheadMax,
        normalDateSettings,
        specialDateSettings
      }
      if (this.form.id && this.form.id !== '0') {
        return this.submitEdit({ ...payload, id: this.form.id })
      } else {
        return this.submitAdd(payload)
      }
    },
    addSpecialDateClock() {
      this.editSpecialDateData.clockList.push({
        clockRange: ['08:00', '10:00'],
        count: 0
      })
    },
    handleEditSpecialDate(val) {
      this.editSpecialDateData.date = val.date
      this.editSpecialDateData.clockList = val.clockList.map(i => {
        i.clockRange = [i.start, i.end]
        return i
      })
      this.editSpecialDateData.isShow = true
    },
    submitEditSpecialDate() {
      const n = this.form.specialData.findIndex(i => i.date === this.editSpecialDateData.date)
      this.form.specialData.splice(n, 1, {
        date: this.editSpecialDateData.date,
        clockList: this.editSpecialDateData.clockList.map(i => {
          i.start = i.clockRange[0]
          i.end = i.clockRange[1]
          return i
        })
      })
      this.editSpecialDateData.isShow = false
    },
    handleSubmitPickDate() {
      if (this.addDateData.date) {
        this.addDateData.date.forEach(item => {
          this.form.specialData.push({
            date: item,
            clockList: []
          })
        })

        this.addDateData.isShow = false
      }
    },
    handleDeleteWeeklyRow(index) {
      this.form.weeklyData.splice(index, 1)
    },
    handleSubmitPickClock() {
      this.form.weeklyData.push({
        clockStart: this.addClockData1,
        clockEnd: this.addClockData2,
        content: [0, 0, 0, 0, 0, 0, 0]
      })
      this.isShowClockPicker = false
    },
    handleAddWeeklyRow() {
      this.isShowClockPicker = true
    },
    getDetail() {
      this.$http
        .get(
          `/boom-center-product-service/sysAdmin/bookingRule/${this.$store.getters['vendor/params/getReservationId']}`
        )
        .then(res => {
          this.form.name = res.ruleName
          this.form.isNeedAhead = !!res.bookAdvanceTime
          this.form.aheadMin = res.bookAdvanceTime / 60
          this.form.aheadMax = res.maxBookAdvanceDay
          this.form.specialData = res.specialDateSettings.map(i => {
            let result = {}
            result.date = this.$options.filters.formatTime(i.specialDay, true)
            result.clockList = i.timeIntervals.map(j => {
              return {
                count: j.resources,
                start: j.startTime,
                end: j.endTime
              }
            })
            return result
          })
          let weeklyData = []
          res.normalDateSettings[0].timeIntervals.forEach((i, n) => {
            weeklyData.push({
              clockStart: i.startTime,
              clockEnd: i.endTime,
              content: res.normalDateSettings.map(j => j.timeIntervals[n].resources)
            })
          })
          this.form.weeklyData = weeklyData
        })
    }
  },
  created() {},
  mounted() {
    this.form.id = this.$store.getters['vendor/params/getReservationId']
    if (
      this.$store.getters['vendor/params/getReservationId'] == 0 &&
      !this.$store.getters['vendor/params/getVendorId']
    ) {
      this.$message.error('参数错误')
    }
    if (this.form.id && this.form.id !== '0') {
      this.getDetail()
    }
  }
}
</script>

<style lang="less" scoped>
.tips {
  color: @color-assist;
}
.el-icon-question {
  color: @color-primary-select;
  margin: 0 5px;
}
@borderStyle: 1px solid #ccc;
.weeklyTable {
  @tableHeaderColor: #7f8c8d;
  .tableRow {
    display: flex;
    align-items: stretch;
  }

  .tableRow {
    text-align: center;
    .name,
    .action,
    .content {
      padding: 7px 0;
      flex-basis: 120px;
      flex-shrink: 0;
      border-top: @borderStyle;
      border-left: @borderStyle;
    }
    .content {
      flex-grow: 1;
    }
    .action {
      border-right: @borderStyle;
    }
    &:last-child {
      .content {
        border-bottom: @borderStyle;
        border-right: @borderStyle;
      }
    }
  }
  .tableHeader {
    background: @tableHeaderColor;
    color: white;
    .name,
    .action,
    .content {
      padding: 0;
    }
  }
}
.specialData {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  .item {
    .itemHeader {
      display: flex;
      justify-content: space-between;
      background: #7f8c8d;
      color: white;
      padding: 5px 10px;
      span {
        font-size: 18px;
      }
    }
    .clockList {
      display: flex;
      flex-wrap: wrap;
      .clockItem {
        flex-basis: 25%;
        flex-grow: 1;
        padding: 7px 15px;
        border: @borderStyle;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
.back {
  padding: 10px 20px;
  display: inline-block;
  cursor: pointer;
  &:hover {
    color: @color-primary-select;
  }
}
</style>
